import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';
import { GAMES, getGameById } from './games';
import logo from './assets/TD-logo.png'; // Adjust path as needed

// GameCard Component
const GameCard = ({ id, title, description, image }) => (
  <Link 
    to={`/game/${id}`} 
    className="block bg-white rounded-3xl shadow-lg p-8 m-4 transition-transform hover:scale-105"
  >
    <img className='border border-gray-200 rounded-lg' src={image} alt={`${title} cover`} />
    <h2 className="text-xl text-center text-gray-800 mb-2">{title}</h2>
    <p className="text-center text-gray-600">{description}</p>
  </Link>
);

// GameWebsite Component (Home Page)
const GameWebsite = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <div className="min-h-screen bg-[#EAE9E3]">
      <header className="py-2 px-2">
        <div className="container mx-auto text-center">
          <div className="w-96 h-96 mx-auto mb-4">
            <img 
              src={logo}
              alt="TyrannoDanno Games Logo"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </header>

      <main className="container mx-auto px-4 py-2">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
          {Object.entries(GAMES).map(([id, game]) => (
            <GameCard 
              key={id}
              id={id}
              title={game.title}
              description={game.description}
              image={game.image}
            />
          ))}
        </div>
      </main>

      <footer className="py-6 px-4 text-center text-gray-600">
        <p>© {currentYear} TyrannoDanno Inc. All rights reserved.</p>
      </footer>
    </div>
  );
};

// GamePage Component
const GamePage = () => {
  const { id } = useParams();
  const currentYear = new Date().getFullYear();
  const gameData = getGameById(id);

  // Handle invalid game ID
  if (!gameData) {
    return (
      <div className="min-h-screen bg-[#EAE9E3] flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Game Not Found</h1>
          <Link to="/" className="text-blue-600 hover:text-blue-800">
            Return to Games List
          </Link>
        </div>
      </div>
    );
  }

  const GameComponent = gameData.component;

  return (
    <div className="min-h-screen bg-[#EAE9E3]">
      <nav className="bg-[#EAE9E3] p-4 shadow-sm">
        <div className="container mx-auto">
          <Link 
            to="/" 
            className="flex items-center text-white bg-green-900 hover:bg-green-600 hover:text-white transition-colors w-fit p-4 rounded-lg"
          >
            <span className="mr-2">←</span>
            <span className="text-lg font-medium">Back to Games</span>
          </Link>
        </div>
      </nav>

      <main className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-3xl shadow-lg overflow-hidden max-w-4xl mx-auto">
          <div className="aspect-video">
            <GameComponent />
          </div>
        </div>
      </main>


      <div className="container mx-auto px-4 pt-2">
        <div className="w-24 h-24 mx-auto">
          <img 
            src={logo}
            alt="TyrannoDanno Games Logo"
            className="w-full h-full object-contain"
          />
        </div>
      </div>

      <footer className="py-2 px-4 text-center text-gray-600">
        <p>© {currentYear} TyrannoDanno Inc. All rights reserved.</p>
      </footer>
    </div>
  );
};

// Main App Component
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<GameWebsite />} />
        <Route path="/game/:id" element={<GamePage />} />
      </Routes>
    </Router>
  );
};

export default App;