import { useState, useEffect, useRef } from 'react';

const Game1 = () => {
  const generateLevel = (levelNum) => {
    const mainPlatforms = [
      { x: 0, y: 380, width: 400 },
      { x: 100, y: 300, width: 100 },
      { x: 250, y: 250, width: 100 },
      { x: 400, y: 280, width: 100 },
      { x: 550, y: 320, width: 100 },
    ];

    // Add goal platform
    const goalPlatform = { x: 700, y: 280, width: 100, isGoal: true };
    const platforms = [...mainPlatforms, goalPlatform];

    const coins = [
      { x: 120, y: 260, collected: false },
      { x: 280, y: 210, collected: false },
      { x: 420, y: 240, collected: false },
      { x: 580, y: 280, collected: false },
    ];

    const possibleHeights = [340, 260, 220, 180];
    const newEnemies = Array(levelNum + 1).fill(null).map(() => {
      const baseX = Math.random() * 500 + 100;
      const patrolDistance = Math.random() * 100 + 100;
      return {
        x: baseX,
        y: possibleHeights[Math.floor(Math.random() * possibleHeights.length)],
        direction: 1,
        speed: 2 + levelNum * 0.5,
        startX: baseX - patrolDistance / 2,
        endX: baseX + patrolDistance / 2
      };
    });

    return { platforms, coins, enemies: newEnemies };
  };

  const [position, setPosition] = useState({ x: 50, y: 0 });
  const [velocity, setVelocity] = useState({ x: 0, y: 0 });
  const [isJumping, setIsJumping] = useState(false);
  const [direction, setDirection] = useState('right');
  const [level, setLevel] = useState(1);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [platforms, setPlatforms] = useState(generateLevel(1).platforms);
  const [coins, setCoins] = useState(generateLevel(1).coins);
  const [enemies, setEnemies] = useState(generateLevel(1).enemies);
  const [cameraOffset, setCameraOffset] = useState(0);
  const controlsRef = useRef(null);

  const resetLevel = () => {
    const levelData = generateLevel(level);
    setPlatforms(levelData.platforms);
    setCoins(levelData.coins);
    setEnemies(levelData.enemies);
    setPosition({ x: 50, y: 0 });
    setVelocity({ x: 0, y: 0 });
    setCameraOffset(0);
    setGameOver(false);
  };

  const handleTouchStart = (direction, e) => {
    e.preventDefault();
    if (gameOver) return;
    if (direction === 'jump' && !isJumping) {
      setVelocity(v => ({ ...v, y: -10 }));
      setIsJumping(true);
    } else {
      setVelocity(v => ({ ...v, x: direction === 'left' ? -5 : 5 }));
      setDirection(direction);
    }
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    setVelocity(v => ({ ...v, x: 0 }));
  };

  useEffect(() => {
    resetLevel();
  }, []);

  useEffect(() => {
    let frameId;
    const gravity = 0.5;
    
    const gameLoop = () => {
      setPosition(pos => {
        const newPos = { ...pos };
        const newVelocity = { ...velocity };

        newVelocity.y += gravity;
        newPos.x += velocity.x;
        newPos.y += velocity.y;

        setCameraOffset(Math.max(0, newPos.x - 200));

        // Platform collision with goal detection
        platforms.forEach(platform => {
          if (newPos.y + 40 >= platform.y && 
              newPos.y + 40 <= platform.y + 20 &&
              newPos.x + 30 >= platform.x && 
              newPos.x <= platform.x + platform.width) {
            newPos.y = platform.y - 40;
            newVelocity.y = 0;
            setIsJumping(false);
            
            // Check if this is the goal platform and player has landed on it
            if (platform.isGoal && !isJumping) {
              setLevel(l => {
                const nextLevel = l + 1;
                const levelData = generateLevel(nextLevel);
                setPlatforms(levelData.platforms);
                setCoins(levelData.coins);
                setEnemies(levelData.enemies);
                return nextLevel;
              });
              newPos.x = 50;
              newPos.y = 0;
              setCameraOffset(0);
              setVelocity({ x: 0, y: 0 });
            }
          }
        });

        // Enemy collision
        enemies.forEach(enemy => {
          if (newPos.x < enemy.x + 30 &&
              newPos.x + 30 > enemy.x &&
              newPos.y < enemy.y + 30 &&
              newPos.y + 40 > enemy.y) {
            setGameOver(true);
          }
        });

        // Coin collection
        coins.forEach((coin, index) => {
          if (!coin.collected &&
              newPos.x < coin.x + 20 &&
              newPos.x + 30 > coin.x &&
              newPos.y < coin.y + 20 &&
              newPos.y + 40 > coin.y) {
            const newCoins = [...coins];
            newCoins[index].collected = true;
            setCoins(newCoins);
            setScore(s => s + 100);
          }
        });

        // Screen boundaries
        if (newPos.x < 0) newPos.x = 0;
        if (newPos.y > 400) {
          setGameOver(true);
        }

        setVelocity(newVelocity);
        return newPos;
      });

      // Update enemy positions
      setEnemies(currentEnemies => 
        currentEnemies.map(enemy => {
          const newX = enemy.x + enemy.direction * enemy.speed;
          if (newX <= enemy.startX || newX >= enemy.endX) {
            return { ...enemy, x: newX, direction: -enemy.direction };
          }
          return { ...enemy, x: newX };
        })
      );

      frameId = requestAnimationFrame(gameLoop);
    };

    if (!gameOver) {
      frameId = requestAnimationFrame(gameLoop);
    }

    return () => cancelAnimationFrame(frameId);
  }, [velocity, platforms, coins, enemies, gameOver, level]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (gameOver) return;
      if (e.key === 'ArrowLeft') {
        setVelocity(v => ({ ...v, x: -5 }));
        setDirection('left');
      }
      if (e.key === 'ArrowRight') {
        setVelocity(v => ({ ...v, x: 5 }));
        setDirection('right');
      }
      if (e.key === 'ArrowUp' && !isJumping) {
        setVelocity(v => ({ ...v, y: -10 }));
        setIsJumping(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'ArrowLeft' && velocity.x < 0) setVelocity(v => ({ ...v, x: 0 }));
      if (e.key === 'ArrowRight' && velocity.x > 0) setVelocity(v => ({ ...v, x: 0 }));
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [velocity.x, isJumping, gameOver]);

  useEffect(() => {
    const controls = controlsRef.current;
    if (controls) {
      const preventDefault = (e) => e.preventDefault();
      controls.addEventListener('touchstart', preventDefault);
      controls.addEventListener('touchmove', preventDefault);
      controls.addEventListener('touchend', preventDefault);
      return () => {
        controls.removeEventListener('touchstart', preventDefault);
        controls.removeEventListener('touchmove', preventDefault);
        controls.removeEventListener('touchend', preventDefault);
      };
    }
  }, []);

  return (
    <div className="w-full max-w-xl mx-auto p-4">
      <div className="relative bg-sky-200 rounded-lg overflow-hidden" style={{ height: '480px' }}>
        <div className="absolute top-4 left-4 z-10 flex gap-4">
          <div className="bg-white px-2 py-1 rounded shadow">Score: {score}</div>
          <div className="bg-white px-2 py-1 rounded shadow">Level: {level}</div>
        </div>

        <div className="absolute inset-0" style={{ transform: `translateX(-${cameraOffset}px)` }}>
          {/* Character */}
          <div
            className={`absolute w-8 h-10 bg-red-500 transition-transform ${
              direction === 'left' ? 'scale-x-[-1]' : ''
            }`}
            style={{
              left: `${position.x}px`,
              top: `${position.y}px`,
            }}
          >
            <div className="w-8 h-6 bg-blue-500 absolute bottom-0" />
            <div className="w-4 h-4 bg-beige-200 rounded-full absolute top-0 left-2" />
          </div>

          {/* Platforms */}
          {platforms.map((platform, index) => (
            <div
              key={index}
              className="absolute"
              style={{
                left: `${platform.x}px`,
                top: `${platform.y}px`,
                width: `${platform.width}px`,
                height: '20px',
                background: platform.isGoal ? 
                  'repeating-linear-gradient(45deg, #000 0, #000 10px, #fff 10px, #fff 20px)' : 
                  '#16a34a'
              }}
            />
          ))}

          {/* Enemies */}
          {enemies.map((enemy, index) => (
            <div
              key={index}
              className="absolute w-8 h-8 bg-red-600 rounded"
              style={{
                left: `${enemy.x}px`,
                top: `${enemy.y}px`,
              }}
            />
          ))}

          {/* Coins */}
          {coins.map((coin, index) => !coin.collected && (
            <div
              key={index}
              className="absolute w-5 h-5 bg-yellow-400 rounded-full"
              style={{
                left: `${coin.x}px`,
                top: `${coin.y}px`,
              }}
            />
          ))}
        </div>

        {gameOver && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
            <div className="text-white text-center">
              <h2 className="text-2xl mb-4">Game Over</h2>
              <button 
                onClick={resetLevel}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Restart Level
              </button>
            </div>
          </div>
        )}

        <div ref={controlsRef} className="lg:hidden absolute bottom-6 w-full flex justify-between px-8 z-10">
          <div className="flex gap-4">
            <button
              className="w-12 h-12 bg-gray-800 rounded-full text-white opacity-50"
              onTouchStart={(e) => handleTouchStart('left', e)}
              onTouchEnd={handleTouchEnd}
            >
              ←
            </button>
            <button
              className="w-12 h-12 bg-gray-800 rounded-full text-white opacity-50"
              onTouchStart={(e) => handleTouchStart('right', e)}
              onTouchEnd={handleTouchEnd}
            >
              →
            </button>
          </div>
          <button
            className="w-12 h-12 bg-gray-800 rounded-full text-white opacity-50"
            onTouchStart={(e) => handleTouchStart('jump', e)}
          >
            ↑
          </button>
        </div>
      </div>
    </div>
  );
};

export default Game1;